<template>
  <div class="takecomplaints" v-loading="loading">
    <div class="flex_between bbe9s">
      <div class="title f-left p10">任务信息</div>
      <div class="flex_start">
        <div class="flex_start mr20">
          <el-button type="info" style="height: 40px" @click="godetail"
            >查看订单详情</el-button
          >
          <el-button
            v-if="wiahulema2"
            type="success"
            @click="waihu('dian')"
            style="height: 40px"
            >外呼{{ waihudian.username }}店长</el-button
          >
          <el-button v-else-if="!wiahulema2" type="danger" style="height: 40px"
            >呼叫中</el-button
          >
          <el-button
            v-if="wiahulema"
            type="warning"
            @click="waihu"
            style="height: 40px"
            >外呼客户</el-button
          >
          <el-button v-else-if="!wiahulema" type="danger" style="height: 40px"
            >呼叫中</el-button
          >
        </div>
        <div style="width: 53px">
          <span
            class="f13 mt30 Shrink"
            @click="Shrink(0)"
            style="color: #aba9a9"
            >{{ isindex[0].msg }}</span
          >
        </div>
      </div>
    </div>
    <!-- 信息内容 -->
    <div
      class="flex_column f14 bbe9s pb20 mt10"
      :style="{ display: isindex[0].tt == 't1' ? 'none' : 'flex' }"
    >
      <div class="p5">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">客户姓名:</span>
              <span>{{ infos2.contacts }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">订单来源:</span>
              <span v-if="infos2.channelTitle != null"
                >{{ infos2.channel }}-{{ infos2.channelTitle }}</span
              >
              <span v-else>{{ infos2.channel }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">房屋问题:</span>
              <!-- <span>{{infos2.problemTitle}}、{{infos2.problemTitle1}}、{{infos2.problemTitle2}}</span> -->
              <span v-if="infos2.problemTitle">{{ infos2.problemTitle }}</span>
              <span v-if="infos2.problemTitle1"
                >、{{ infos2.problemTitle1 }}</span
              >
              <span v-if="infos2.problemTitle2"
                >、{{ infos2.problemTitle }}</span
              >
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">联系方式:</span>
              <span>{{ infos2.telephone }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">下单时间:</span>
              <span>{{ infos2.created_time }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start flex_items mt10">
              <span class="mr10 t1sp">家庭地址:</span>
              <div>{{ infos2.addres }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="flex_start flex_items p5">
        <span class="mr10 t1sp">添加微信:</span>
        <div style="width: 574px">
          <span v-if="infos2.wechat == 0">否</span>
          <span v-else>是</span>
        </div>
      </div>
      <div class="flex_start flex_items p5">
        <span class="mr10 t1sp">备注信息:</span>
        <div style="width: 574px">{{ infos2.remarks }}</div>
      </div>
    </div>
    <div>
      <span class="f13 mt10 Shrink" @click="Shrink(1)" style="color: #aba9a9">{{
        isindex[1].msg
      }}</span>
    </div>
    <!-- 投诉详情 -->
    <div class="flex_between mt10 bbe9s">
      <div class="title f-left p10">投诉详情</div>
    </div>
    <!-- 内容 -->
    <div
      class="flex_column f14 bbe9s pb20"
      :style="{ display: isindex[1].tt == 't1' ? 'none' : 'flex' }"
    >
      <div class="p5">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">投诉时间:</span>
              <span>{{ infos2.create_time }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">投诉对象:</span>
              <span>{{ duixiang }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">负责店长:</span>
              <span>{{ infos2.username }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">投诉原因:</span>
              <span>{{ infos2.reason }}</span>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">生成人员:</span>
              <span>{{ adminName }}</span>
              <!-- <span v-if="infos2.admins.length>0">{{infos2.admins[0]}}</span> -->
            </div>
          </el-col>
          <el-col :span="8">
            <div class="flex_start mr20 flex_items mt10">
              <span class="mr10 t1sp">客户要求:</span>
              <span>{{ infos2.requirements }}</span>
            </div>
          </el-col>
        </el-row>
        <div class="flex_start mr20 flex_items mt10">
          <span class="mr10 t1sp">问题图片:</span>
          <div class="flex_warp" style="width: 600px">
            <div
              v-for="(item, index) in infos2.picture"
              :key="index"
              :data-img="infos2.picture"
              class="mr20 mt10"
              @click="$imgPreview"
            >
              <img class="imgcar" :src="item" alt />
            </div>
          </div>
        </div>
      </div>
      <!-- 投诉处理记录 -->
      <div class="flex_column pb10 mt10">
        <div class="title f-left p10 bbe9s">投诉处理记录</div>
        <div class="mt20 innerkuang">
          <el-table :data="infos2.through" border>
            <el-table-column label="处理人">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.role == 1"
                    >{{ scope.row.username }}/客服</span
                  >
                  <span v-else>{{ scope.row.username }}/店长</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="th_time" label="操作时间"></el-table-column>
            <el-table-column
              prop="end_time"
              label="下次跟进时间"
            ></el-table-column>
            <el-table-column prop="username" label="是否接通">
              <template slot-scope="scope">
                <span v-if="scope.row.conversation == 0">是</span>
                <span v-if="scope.row.conversation == 2">否</span>
              </template>
            </el-table-column>
            <el-table-column prop="remar" label="沟通内容"></el-table-column>
            <el-table-column label="沟通录音">
              <template slot-scope="scope">
                <div v-if="scope.row.record.length == 0">暂无录音</div>
                <div v-else>
                  <audio
                    style="width: 100%; outline: none"
                    :src="scope.row.record[0].monitorfilename"
                    controls="controls"
                  ></audio>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="shopowner" label="处理店长">
              <span>{{ infos2.username }}</span>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div>
      <span class="f13 mt10 Shrink" @click="Shrink(2)" style="color: #aba9a9">{{
        isindex[2].msg
      }}</span>
    </div>
    <!-- 任务操作-投诉回访 -->
    <div class="flex_between pb10 mt10">
      <div class="title f-left p10 bbe9s">任务操作-投诉回访</div>
    </div>
    <div
      :style="{ display: isindex[2].tt == 't1' ? 'none' : 'flex' }"
      class="flex_column bbe9s"
    >
      <div class="flex_warp">
        <div class="flex_start flex_items mt20 mr20">
          <span class="mr20 isok" style="width: 100px">下次跟进时间</span>
          <el-date-picker
            :clearable="true"
            v-model="isok.time1"
            type="datetime"
            placeholder="选择下次跟进时间"
          ></el-date-picker>
        </div>
        <div class="m-r-80 flex_start flex_items mt20">
          <span class="mr20 isok">客户电话是否接通</span>
          <el-radio-group v-model="isok.istong" class="flex_items f14">
            <el-radio :label="0">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </div>
        <div class="m-r-80 flex_start flex_items mt20">
          <span class="mr20 isok" style="width: 100px">是否处理完成</span>
          <el-radio-group v-model="isok.ischuli" class="flex_items f14">
            <el-radio :label="1" @click.native.prevent="clickitem('ischuli', 1)"
              >是</el-radio
            >
            <el-radio :label="2" @click.native.prevent="clickitem('ischuli', 2)"
              >否</el-radio
            >
          </el-radio-group>
        </div>
        <div class="m-r-80 flex_start flex_items mt20">
          <span class="mr20 isok" style="width: 74px">对处理过程</span>
          <el-radio-group v-model="isok.isguo" class="flex_items f14">
            <el-radio
              label="非常满意"
              class="mr10"
              @click.native.prevent="clickitem('isguo', '非常满意')"
              >非常满意</el-radio
            >
            <el-radio
              label="满意"
              class="mr10"
              @click.native.prevent="clickitem('isguo', '满意')"
              >满意</el-radio
            >
            <el-radio
              label="一般"
              class="mr10"
              @click.native.prevent="clickitem('isguo', '一般')"
              >一般</el-radio
            >
            <el-radio
              label="不满意"
              class="mr10"
              @click.native.prevent="clickitem('isguo', '不满意')"
              >不满意</el-radio
            >
          </el-radio-group>
        </div>
        <div class="m-r-80 flex_start flex_items mt20">
          <span class="mr20 isok" style="width: 74px">对处理结果</span>
          <el-radio-group v-model="isok.isjieguo" class="flex_items f14">
            <el-radio
              label="非常满意"
              class="mr10"
              @click.native.prevent="clickitem('isjieguo', '非常满意')"
              >非常满意</el-radio
            >
            <el-radio
              label="满意"
              class="mr10"
              @click.native.prevent="clickitem('isjieguo', '满意')"
              >满意</el-radio
            >
            <el-radio
              label="一般"
              class="mr10"
              @click.native.prevent="clickitem('isjieguo', '一般')"
              >一般</el-radio
            >
            <el-radio
              label="不满意"
              class="mr10"
              @click.native.prevent="clickitem('isjieguo', '不满意')"
              >不满意</el-radio
            >
          </el-radio-group>
        </div>
      </div>
      <!-- 客户反馈备注 -->
      <div class="flex_start flex_items m-r-60 mt20 pb20">
        <span class="mr20" style="width: 100px">客户反馈备注</span>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入客户反馈备注信息"
          v-model="isok.textarea"
          style="width: 310px"
        ></el-input>
      </div>
    </div>
    <!-- 提交 -->
    <div class="flex_start mt30">
      <div style="margin: 0 auto">
        <el-button class="mr20" type="primary" @click="tijiao('kai')"
          >提交处理结果</el-button
        >
        <el-button @click="tijiao('guan')">关单</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["currentPage3"],
  data() {
    return {
      loading: true,
      isindex: [
        {
          tt: "-1",
          msg: "--收起--",
        },
        {
          tt: "t1",
          msg: "--展开--",
        },
        {
          tt: "t1",
          msg: "--展开--",
        },
      ],
      isok: {
        istong: 2,
        time1: "",
        textarea: "",
        isguo: "非常满意",
        ischuli: 2,
        isjieguo: "非常满意",
      },
      infos: {},
      infos2: {},
      duixiang: "",
      // 外呼
      wiahulema: true,
      wiahulema2: true,
      jige: "",
      wiahulema: true,
      wiahulema2: true,
      waihulun: 0,
      waihudian: { username: "", mobile: "" },
      isSaveState: true,
      ExternalData: "",
      call: [],
      adminName: "",
    };
  },
  // mounted() {
  //   this.getData();
  // },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.util
        .get(this.HOST + "/Order/info", {
          order_id: this.$store.state.order_id,
          Colloquialism: 4,
          // order_id: "4877"
        })
        .then((res) => {
          if (res.code == 200) {
            this.infos2 = res.data;
            if (res.data.admins.length > 0) {
              this.adminName = res.data.admins[0];
            }
            this.isok.tousutime = this.util.intertime(this.util.getnow());
            let arr = [];
            if (res.data.shopowner.length > 0) {
              for (let i = 0; i < res.data.shopowner.length; i++) {
                arr.push(res.data.shopowner[i].username);
              }
            }
            if (res.data.masterworker.length > 0) {
              for (let j = 0; j < res.data.masterworker.length; j++) {
                arr.push(res.data.masterworker[j].username);
              }
            }
            this.duixiang = JSON.stringify(arr)
              .replace(/\[|]/g, "")
              .replace(/\"/g, "");
            // this.loading = false;
            this.dianzhangphone();
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 获取店长号码名字
    dianzhangphone() {
      this.util
        .get(this.HOST + "/User/findShopOwner", {
          order_id: this.$store.state.order_id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.waihudian = res.data;
            this.loading = false;
          } else {
            this.loading = false;
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 跳转详情
    godetail() {
      this.util.godetail(this.infos2);
    },
    // 发起外呼
    waihu(aa) {
      if (!this.wiahulema || !this.wiahulema2) {
        this.$message({
          showClose: true,
          message: "不能同时呼叫!",
          type: "warning",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      let phone = "";
      if (aa == "dian") {
        if (this.waihudian.username == "") {
          this.$message({
            showClose: true,
            message: "该订单暂未指派店长!",
            type: "warning",
            duration: 1200,
            offset: 90,
          });
          return false;
        } else {
          phone = this.waihudian.mobile;
        }
      } else {
        phone = this.waihudian.telephone;
      }
      // 打包后
      this.$jsonp(this.HOST4, {
        account: "N000000015324",
        appid: "ncvsorfw58bk2o6r",
        secret: "31792f00-d2e5-11e9-8be9-b3731625d6b2",
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
          this.kaihu(res.accessToken, phone, aa);
        }
      });
    },
    kaihu(token, phone, aa) {
      let timestamp = new Date().getTime();
      let good = "customer";
      if (aa == "dian") {
        good = "shopowner";
      }
      let kk = timestamp + "-" + good;
      this.lunxun(kk);
      // 打包后
      this.$jsonp(this.HOST5, {
        phonenum: phone,
        integratedid: sessionStorage.getItem("integratedid"),
        accessToken: token,
        account: "N000000015324",
        flag: 106,
        ExternalData: "main:" + kk,
        output: "jsonp",
      }).then((res) => {
        if (res.success) {
          if (aa == "dian") {
            this.wiahulema2 = false;
          } else {
            this.wiahulema = false;
          }
        }
      });
    },
    lunxun(kk) {
      this.waihulun = 1;
      let that = this;
      let arr = [];
      that.iCount = window.setInterval(function () {
        that.$http
          .get(that.HOST + "/Callback/jie", {
            params: {
              ExternalData: kk,
            },
          })
          .then((res) => {
            if (res.data.code == 200) {
              that.waihulun = 0;
              that.wiahulema = true;
              that.wiahulema2 = true;
              that.isSaveState = true;
              that.call.push(res.data.data.id);
              clearInterval(that.iCount);
            } else {
              arr.push(res.data.code);
              if (arr.length == 35) {
                that.waihulun = 0;
                that.wiahulema = true;
                that.wiahulema2 = true;
                that.isSaveState = true;
                arr = [];
                clearInterval(that.iCount);
              }
            }
          })
          .catch(function (error) {
            // 请求失败处理
            window.console.log(error);
          });
      }, 2000);
    },
    tijiao(guan) {
      let types = 9;
      let text = "任务处理中...";
      if (guan == "guan") {
        types = 10;
        text = "关单中...";
      }
      let order_id = this.$store.state.order_id;
      let end_time = this.util.datatime(this.isok.time1);
      if (end_time == "NaN-NaN-NaN NaN:NaN:NaN" && guan == "kai") {
        this.$message({
          showClose: true,
          type: "error",
          message: "请输入下次跟进时间",
          duration: 1200,
          offset: 90,
        });
        return false;
      }
      // return false;
      if (end_time == "NaN-NaN-NaN NaN:NaN:NaN") {
        end_time = "";
      }
      this.$loading({
        fullscreen: true,
        background: "rgba(0, 0, 0, 0.7)",
        text: text,
      });
      this.util
        .post(this.HOST + "/Order/ProcessingTasks", {
          order_id: order_id,
          through_id: 0,
          type: types,
          connect: this.isok.istong,
          remar: this.isok.textarea,
          complete: this.isok.ischuli,
          process: this.isok.isguo,
          result: this.isok.isjieguo,
          end_time: end_time,
          cc_id: this.$store.state.cc_id,
          user_id: "",
          Call_record: this.call,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: "任务处理成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            setTimeout(() => {
              this.$store.commit("setMessage", false);
              this.$store.commit("settask", 1);
              this.$emit("getDataAgain", this.currentPage3);
              this.$emit("update:currentPage3", this.currentPage3);
            }, 1200);
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    // 单选点击取消
    clickitem(kind, e) {
      if (kind == "ischuli") {
        this.isok.ischuli = this.util.cancleradio(kind, e, this.isok.ischuli);
      } else if (kind == "isguo") {
        this.isok.isguo = this.util.cancleradio(kind, e, this.isok.isguo);
      } else if (kind == "isjieguo") {
        this.isok.isjieguo = this.util.cancleradio(kind, e, this.isok.isjieguo);
      }
    },
    // 收缩
    Shrink(num) {
      if (this.isindex[num].tt == "t1") {
        this.isindex[num].tt = "-1";
        this.isindex[num].msg = "--收起--";
      } else {
        this.isindex[num].tt = "t1";
        this.isindex[num].msg = "--展开--";
      }
    },
  },
};
</script>
<style>
.isok {
  color: #3f51b5;
}
.imgcar {
  width: 100px;
  height: 100px;
  cursor: pointer;
}
</style>
